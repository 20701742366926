body {
  margin: 0;
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body > iframe {
  display: none;
}
.z-z-2 {
  z-index: 2;
}
.z-z-1 {
  z-index: 1;
}
.z-z-0 {
  z-index: 0;
}
.opacity--0 {
  opacity: 0;
}
.primary-text {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

div,
button,
p,
a,
input {
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  display: block;
  outline: none;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

:root {
  --toastify-color-light: #121212;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #121212;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Clash Display";
  --toastify-z-index: 9999;

  --toastify-text-color-light: #fff;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
.Toastify__toast-container--bottom-right div {
  font-family: "Clash Display" !important;
}

.btn-loading-primary {
  font-family: 'Clash Display' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  background: linear-gradient(99.9deg, #3B67FF 3.81%, #C273F2 46.18%);
  border-radius: 60px !important;
  color: white !important;
  border: none;
  text-transform: capitalize !important;
}
.btn-loading-primary:disabled {
  color: transparent !important;
  opacity: 0.6;
}
.btn-loading-secondary {
  font-family: 'Clash Display' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: white !important;
  border: 1px solid #C273F2 !important;
  border-radius: 60px !important;
  text-transform: capitalize !important;
}
.btn-loading-secondary:disabled {
  color: transparent !important;
  opacity: 0.6;
}

@keyframes moveOutOpacity {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes moveInOpacity {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes spinImg {
  from {
    opacity: 0;
    transform: rotate(45deg) scale(0);
  }
  to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}
@keyframes testAnim {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
